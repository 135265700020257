<template>
  <div>
    <div class="CSmainbox">
      <div class="CSbox1">
        <p class="CSbox1p">
          选择客服
        </p>
        <el-select v-model="ChangeServeRadio" filterable placeholder="请选择" @change="change" size="small">
          <el-option
            v-for="item in servecheckList"
            :key="item.id"
            :label="item.account || item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="CSleft">
        <p class="CSleftp">
          该客服下的用户
        </p>
        <div style="padding-left: 90px;">
          <div class="CSsearch">
            <el-input
              placeholder="请输入关键字(姓名或电话号或用户编号)"
              clearable
              prefix-icon="el-icon-search"
              v-model="input2"
              @clear="clear"
              size="small"
            >
            </el-input>
            <el-button type="primary" @click="ChangeServeQuery('')" size="small">查询</el-button>
            <el-button type="primary" @click="dialogVisible = true" size="small">转移服务人</el-button>
          </div>
        </div>

        <el-table
          :data="userlist"
          border
          style="width: 100%; margin-top: 40px"
          height="500"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          :row-key="
            (row) => {
              return row.id;
            }
          "
          ref="multipleTable"
        >
          <el-table-column type="selection" :reserve-selection="true" width="55"></el-table-column>
          <el-table-column prop="id" label="手机号"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="desc" label="备注"></el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <el-table-column prop="serviceId" label="话务客服ID" sortable></el-table-column>
        </el-table>

        <p class="paging" style="text-align:center;">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="current"
            :page-sizes="pageSizes"
            :page-size="PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
            :hide-on-single-page="value"
          ></el-pagination>
        </p>
      </div>

      <el-dialog title="" :visible.sync="dialogVisible" width="30%">
        <span style="margin-right: 20px">请选择转移到的客服</span>
        <el-select v-model="ChoiceServeRadio" filterable placeholder="请选择" value-key="id">
          <el-option
            v-for="item in servecheckList"
            :key="item.id"
            :label="item.account || item.name"
            :value="item"
          >
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subChangeServer02">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>export default {
  data() {
    return {
      sid: sessionStorage.getItem("id"),
      uid: sessionStorage.getItem("servicer_id"),
      ChangeServeRadio: [], //选择查询的客服fromid
      servecheckList: [], //客服列表
      input2: "", //需要根据电话号、用户编号查询的患者
      userlist: [], //用户列表
      userlist1: [], //需处理的用户列表
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [50], // 个数选择器（可修改）
      PageSize: 50, // 默认每页显示的条数（可修改）
      value: false,
      servicelist: [], //患者在话务系统对应的客服ID
      ChangeServeCheckList: [], //选中的用户
      usercheckList: [],
      dialogVisible: false,
      ChoiceServeRadio: [], //客服toid
    };
  },
  mounted() {
    this.getservecheckList();
  },
  methods: {
    //获取客服列表
    getservecheckList() {
      this.axios.get(this.serveurl.serve + "/api/Employee/get_employee?Id=" + this.uid).then((res) => {
        if (res.data.code == 1) {
          this.servecheckList = res.data.result;
        }
      });
    },
    //选择服务人查询
    change() {
      this.usercheckList = [];
      this.ChangeServeCheckList = [];
      this.getusercheckList(1);
    },
    //查询客服下的用户
    getusercheckList(page) {
      this.userlist = [];
      //   this.ChangeServeCheckList = [];
      //   this.usercheckList = [];
      this.$nextTick(() => {
        this.$refs.multipleTable.clearSelection();
      });
      var id = this.filterid(this.ChangeServeRadio);
      this.axios
        .get(this.serveurl.serve + "/api/User/get_user_with_relation?userID=" + id + "&index=" + page)
        .then((res) => {
          console.log(res);
          if (res.data.code == 1) {
            this.userlist1 = res.data.result;
            this.totalCount = res.data.count;
            if (this.userlist1.length > 0) {
              //需要用手机号换话务系统内的客服编号
              var str = "";
              this.userlist1.forEach((e) => {
                str += "'" + e.id + "',";
              });
              str = str.substr(0, str.length - 1);
              this.axios
                .post("/chat/get_user_cust_servicer", this.qs.stringify({ tels: str }))
                .then((res) => {
                  if (res.data.code == 0) {
                    this.servicelist = res.data.result;
                    this.userlist1.forEach((item) => {
                      this.servicelist.forEach((tem) => {
                        if (item.id == tem.Tel1) {
                          item.serviceId = tem.Cust_Servicer;
                          item.custId = tem.Cust_ID;
                        }
                      });
                    });
                    this.userlist = this.userlist.concat(this.userlist1);
                  }
                });
            }
          }
        })
        .catch((res) => {});
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getusercheckList(currentPage);
    },
    //修改服务人查询用户
    ChangeServeQuery(a) {
      if (this.ChangeServeRadio == "") {
        this.$message({
          message: "请选择客服",
          type: "error",
        });
        return;
      }
      this.$nextTick(() => {
        this.$refs.multipleTable.clearSelection();
      });
      var id = this.filterid(this.ChangeServeRadio);
      this.axios.get("/gu/get_cust_info_by_custid?cust_id=" + this.input2).then((res) => {
        if (res.data.code == 0) {
          this.getservice(id, res.data.result.Tel1);
        } else {
          this.getservice(id, this.input2);
        }
      });
    },
    getservice(id, val) {
      this.axios
        .get(this.serveurl.serve + "/api/User/search_user_without_name?userID=" + id + "&name=" + val)
        .then((res) => {
          if (res.data.code == 1) {
            if (res.data.result.length > 0) {
              this.userlist1 = res.data.result;
              this.totalCount = res.data.count;
              var str = "";
              this.userlist1.forEach((e) => {
                str += "'" + e.id + "',";
              });
              str = str.substr(0, str.length - 1);
              this.axios
                .post("/chat/get_user_cust_servicer", this.qs.stringify({ tels: str }))
                .then((res) => {
                  if (res.data.code == 0) {
                    this.servicelist = res.data.result;
                    this.userlist1.forEach((item) => {
                      this.servicelist.forEach((tem) => {
                        if (item.id == tem.Tel1) {
                          item.serviceId = tem.Cust_Servicer;
                          item.custId = tem.Cust_ID;
                        }
                      });
                    });
                    this.userlist = this.userlist1;
                  }
                });
            } else {
              this.$message({
                message: "没有在该客服下搜索到此用户",
                type: "error",
              });
            }
          } else {
          }
        })
        .catch((res) => {});
    },
    //多选中数组操作
    handleSelectionChange(val) {
      this.usercheckList = [];
      this.ChangeServeCheckList = [];
      this.usercheckList = val;
      if (val.length > 0) {
        for (let a = 0; a < val.length; a++) {
          this.ChangeServeCheckList.push(val[a].id);
        }
      }
      console.log(this.ChangeServeCheckList);
    },
    //提交要修改的服务人
    subChangeServer02() {
      if (this.ChangeServeCheckList.length <= 0) {
        this.$message({
          message: "请选择用户",
          type: "error",
        });
      } else if (this.ChoiceServeRadio == "") {
        this.$message({
          message: "请选择客服",
          type: "error",
        });
      } else {
        var id = this.filterid(this.ChoiceServeRadio.id);
        console.log(id);
        console.log(this.ChangeServeRadio);
        console.log(this.ChangeServeCheckList);
        //聊天修改服务人员
        this.axios
          .post(
            this.serveurl.serve + "/api/User/change_users_service",
            this.qs.stringify({
              targetID: id,
              userIDList: this.ChangeServeCheckList,
              agent: sessionStorage.getItem("servicer_id"),
              fromId: this.ChangeServeRadio,
            })
          )
          .then((res) => {
            if (res.data.code == 1) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.getusercheckList(1);
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        this.removeMsgList(this.ChangeServeRadio, this.ChangeServeCheckList);
        var oldid = this.filterid(this.ChangeServeRadio);
        var _cust_servicer = this.ChoiceServeRadio.serviceId;
        for (let i = 0; i < this.ChangeServeCheckList.length; i++) {
          this.axios
            .post(
              "/log/chat_login_log",
              this.qs.stringify({
                data:
                  "【修改服务人】 " +
                  sessionStorage.getItem("id") +
                  "  ---   " +
                  sessionStorage.getItem("name") +
                  "转移得用户姓名：" +
                  this.ChangeServeCheckList[i],
              })
            )
            .then((res) => {});
          //清除转移服务人时的未读
          this.axios
            .post(
              this.serveurl.serve + "/api/User/read_message",
              this.qs.stringify({
                fromID: this.ChangeServeCheckList[i],
                toID: oldid,
              })
            )
            .then((res) => {
              if (res.data.code == 1) {
                console.log(res);
              } else {
                console.log(res);
              }
            })
            .catch((error) => {
              console.error(error.message);
            });
          //根据电话号查询患者的cust_id
          this.axios
            .get("/gu/get_cust_info_by_tel?tel=" + this.ChangeServeCheckList[i])
            .then((res) => {
              if (res.data.code == 0) {
                var custID = res.data.Cust_ID;
                var _assigner = sessionStorage.getItem("servicer_id");
                //改变服务人同步话务系统
                this.axios
                  .post(
                    "/chat/insert_assigntable",
                    this.qs.stringify({
                      cust_id: custID,
                      assigner: _assigner,
                      cust_servicer: _cust_servicer,
                    })
                  )
                  .then((res) => {
                    if (res.data.code == 0) {
                      console.log(res);
                    } else {
                      console.log(res);
                    }
                  });
              }
            });
        }
      }
      this.dialogVisible = false;
      this.ChangeServeCheckList = [];
      this.ChoiceServeRadio = "";
      //   this.ChangeServeRadio = "";
      this.input2 = "";
      this.usercheckList = [];
    },
    //转移完服务人之后删除消息
    removeMsgList(sid, arr) {
      arr.forEach((item) => {
        this.axios
          .post(
            this.serveurl.serve + "/api/MessageStore/remove",
            this.qs.stringify({ service: sid, userId: item })
          )
          .then((res) => {
            if (res.data.code == 1) {
              console.log(res);
              console.log("移除成功");
            }
          });
      });
    },
    clear() {
      this.input2 = "";
      this.usercheckList = [];
      this.ChangeServeCheckList = [];
      this.getusercheckList(1);
    },
    //对客服列表的id进行处理
    filterid(val) {
      val = String(val);
      if (val.substr(0, 1) == "s") {
        return val;
      } else {
        return "s" + val;
      }
    },
  },
};
</script>
<style>
.CSmainbox {
  width: 100%;
  height: 720px;
  text-align: left;
}

.CSleft {
  width: 100%;
  float: left;
}

.CSsearch {
  width: 100%;
  height: 40px;
  margin: 10px 0;
}

.CSsearch .el-input {
  width: 300px;
  float: left;
}

.CSsearch .el-input__inner {
  float: left;
}

.CSsearch .el-input__icon {
  line-height: 30px;
}

.CSsearch .el-button {
  margin-left: 10px;
}

.CSleft .infinite-list {
  width: 100%;
  height: 350px;
  text-align: left;
  border: 1px solid #dcdfe6;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

.CStips {
  width: 8%;
  float: left;
  height: 100%;
  line-height: 150px;
}

.CSright {
  width: 46%;
  float: right;
}

.CSkfList {
  width: 100%;
  height: 380px;
  text-align: left;
  border: 1px solid #000;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}
.CSbox1 {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}
.CSbox1p {
  width: 90px;
  float: left;
  text-align: right;
  padding-right: 10px;
}
.CSleftp {
  width: 90px;
  float: left;
  text-align: right;
  padding-right: 10px;
}
</style>
